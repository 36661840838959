
.buy-control-panel-container {
	display: block;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 0.3rem !important;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 10px;
	background-color: #2E2E2E;
	max-width: 1000px;
}

.sort-by-dropdown {
	box-shadow: none !important;
}

.sort-by-dropdown-item {
	font-size: 1rem;
}

.sort-by-dropdown-item-selected {
	font-weight: bolder;
	font-size: 1.2rem;
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-button-group {
	margin-left: 10px;
	margin-right: auto;
}

.buy-help-button {
	margin-left: auto !important;
	margin-right: 10px;
	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 8px;
	padding-right: 8px;
	font-size: 1.4rem;
	box-shadow: none;
	background-color: transparent;
	outline: none;
	border-style: none;
	cursor: pointer;
}

.list-layout-button {
	box-shadow: none !important;
	cursor: pointer;
}

.grid-layout-button {
	box-shadow: none !important;
	cursor: pointer;
}

.list-star-button {
	box-shadow: none !important;
	cursor: pointer;
	margin-left: 10px;
	margin-right: 10px;
}

.layout-button-group {
	margin-left: 10px;
	margin-right: 10px;
}

.control-panel-title-buy {
	color: white !important; 
	font-size: 1.65rem;
	margin-left: auto;
	margin-right: auto;
}

.control-panel-title-search {
	color: white !important; 
	font-size: 1.5rem;
	margin-left: 20px;
	margin-right: 20px;
}
