
.purchase-button {
	font-size: 1.3rem !important;
	font-weight: bold !important;
	padding-left: 16px !important;
	padding-right: 16px !important;
	color: white !important;
	background-color: #00C853 !important;
	border-color: transparent !important;
}

.purchase-button-mobile {
	font-size: 1.1rem !important;
	font-weight: bold !important;
	padding-left: 12px !important;
	padding-right: 12px !important;
	color: white !important;
	background-color: #00C853 !important;
	border-color: transparent !important;
}

.purchase-button-no-wallet {
	font-size: 1.4rem !important;
	font-weight: bold !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
	color: white !important;
	background-color: #00C853 !important;
	border-color: transparent !important;
}

.purchase-button:hover {
	border-color: black !important;
}

.purchase-button-mobile:active {
	border-color: black !important;
}

.purchase-button-mobile:hover {
	border-color: black !important;
}

.purchase-button-no-wallet:disabled {
	border-color: transparent !important;
}

.purchase-button-no-wallet:hover {
	border-color: #212121 !important;
}

.no-wallet-notice {
	font-size: 1rem !important;
	text-align: center !important;
	color: white !important;
	display: block;
	margin-top: 5px;
	margin-bottom: 4px;
}

.buttons-container {
	margin-top: 5px;
	margin-bottom: 5px;
}

.download-sigil-button {
	font-size: 0.8rem !important;
	color: white !important;
	background-color: #676370 !important;
	margin-right: 3px;
	padding-top: 9px;
	padding-left: 10px;
	padding-right: 9px;
	padding-bottom: 9px;
	text-decoration: none !important;
	border-style: none !important;
}

.copy-sigil-button {
	font-size: 0.8rem !important;
	color: white !important;
	background-color: #676370 !important;
	margin-right: 3px;
	padding-top: 6px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 6px;
	text-decoration: none !important;
	border-color: transparent !important;
}

.share-button {
	font-size: 0.8rem !important;
	color: black !important;
	margin-top: -1px !important;
	background-color: white !important;
	margin-left: 3px;
	padding-top: 6px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 6px;
	border-color: transparent !important;
}

.download-sigil-button:hover {
	box-shadow: inset 1px 1px 1px #212121;
}

.share-button:hover {
	border-color: #212121 !important;
}

.data-row,
.data-row-link {
	color: white !important;
	font-size: 1.3rem;
	border-style: none !important;
}

.data-row-link:hover {
	color: grey !important;
	text-decoration: none !important;
}

.buy-row-no-wallet {
	padding-bottom: 4px !important;
}

.point-row {
	cursor: pointer;
	line-height: 1.5;
}

.keyrev-row {
	line-height: 1.5;
}

.owner-row {
	word-break: break-all;
	line-height: 1.5;
	font-size: 1.2rem !important;
}

.point-copy-button {
	color: #999999;
	font-size: 1rem;
	margin-left: 5px;
	padding-top: 0px;
	background-color: transparent;
	border-color: transparent;
	outline: none !important;
	cursor: pointer !important;
}

.point-copy-button:hover { 
	color: #595959;
}

.point-copied {
	color: #787878;
	font-size: 1.1rem;
}

.spawned-deck {
	min-height: 10vh;
  display: inline-flex;
  align-items: center;
  justify-content: normal;
  color: white;
}

.spawned-deck-mobile {
	min-height: 10vh;
  display: inline-flex;
  align-items: center;
  justify-content: normal;
  margin-bottom: 0.5vmin;
  color: white;
}

.spawned-deck-top {
	margin-top: 30px !important;
	margin-bottom: 15px !important;
}

.spawned-deck-other {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.spawned-deck-bottom {
	margin-top: 15px !important;
  margin-bottom: 30px !important;
}

.spawned-card-link:hover {
	text-decoration: none !important;
}

.spawned-card {
	min-width: 175px !important;
	cursor: pointer;
	margin-left: 15px !important;
	margin-right: 15px !important;
	background-color: #2E2E2E;
	transition: 0.3s;
}

.spawned-card:hover {
	transform: translate(0px, -2px);
}

.spawned-card-header {
	padding: 5px !important;
	margin: 5px !important;
	font-size: 1.1rem !important;
	color: white;
}

.ship-view-card-sigil-container {
	margin-left: 10px;
	margin-right: 10px;
}

.complete-list-planet-card {
	min-width: 180px !important;
	cursor: pointer;
	margin-left: 12px !important;
	margin-right: 12px !important;
	background-color: #2E2E2E;
	transition: 0.3s;
}

.complete-list-planet-card:hover {
	transform: translate(0px, -2px);
}

.complete-list-planet-card-selected {
	width: 15vw !important;
	margin: 1vmin !important;
	min-width: 260px !important;
	max-width: 275px !important;
}

.planet-list-tab-link:hover {
	border-style: none !important;
	color: grey !important;
}

.planet-list-tab-link:active {
	color: #212121 !important;
}

.planet-list-tab-link {
	font-size: 1.2rem !important;
	color: white;
}

.breadcrumb {
  background-color: transparent !important;
  margin-bottom: 5px !important;
}

.breadcrumb a:hover {
  color: grey !important;
	text-decoration: none !important;
}

ol > li > a {
	color:white
}

.ship-sigil {
	display: inline-block !important;
	margin-left: auto !important;
	margin-bottom: 0px !important;
	margin-right: 4px;
}

.color-tile-container {
	padding: 0;
	height: 12px;
}

.color-tile {
	height: 12px;
	margin: 0;
	border-color: transparent !important;
	border-style: none !important;
	border-radius: 0;
	vertical-align: top;
	transition: 0.3s;
}

.color-tile:focus {
	box-shadow: none !important;
}

.color-tile:hover {
	transform: translate(0px, -2px);
}

.page-link {
	color: #212121 !important;
}

.page-item.active > .page-link {
	color: white !important;
	background-color: #212121 !important;
	border-color: white !important;
}
