@import url(https://fonts.googleapis.com/css?family=Karla);
@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.title-container {
	color: white;
	font-size: 2.5rem;
	margin-bottom: 35px;
	padding-top: 10px;
}

.title-container-mobile {
	color: white;
	font-size: 2rem;
	margin-bottom: 30px;
	padding-top: 10px;
}

.shop-button-container {
	max-width: 625px;
	margin-top: 20px;
	margin-bottom: 70px;
	margin-left: auto;
	margin-right: auto;
}

.shop-button-container-mobile-portrait {
	margin-top: 20px;
	margin-bottom: 50px;
	margin-left: 40px;
	margin-right: 40px;
}

.shop-button-container-mobile-landscape {
	max-width: 425px;
	margin-top: 20px;
	margin-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
}

.shop-button {
	color: #2E2E2E;
	padding-top: 30px;
	padding-bottom: 20px;
	transition: 0.3s;
}

.shop-button:hover {
	text-decoration: none;
	color: #212121;
  transform: translate(0px, -3px);
}

.shop-button-mobile {
	color: #2E2E2E;
	padding-top: 30px;
	padding-bottom: 20px;
	transition: 0.3s;
}

.shop-button-mobile:hover {
	text-decoration: none;
	color: #212121;
}

.shop-button-icon-container {
	margin-left: 5px;
	margin-right: auto;
	font-size: 3.5rem;
	display: flex;
}

.shop-button-icon {
	margin-left: -6px !important;
	margin-right: -6px !important;
	padding-top: 10px !important;
}

.shop-button-icon-middle {
	margin-left: -4px !important;
	margin-right: -4px !important;
	padding-top: 14px !important;
}

.shop-button-text-container {
	margin-left: auto;
	margin-right: auto;
}

.shop-button-text-container-mobile {
	margin-left: auto;
	margin-right: auto;
}

.shop-button-text-title {
	font-size: 2.5rem;
	font-weight: 550;
}

.shop-button-text-title-mobile {
	font-size: 1.8rem;
	font-weight: 550;
}

.carat-container {
	margin-left: auto;
	font-size: 3.5rem;
}

.carat-container-mobile {
	margin-left: auto;
	font-size: 2.6rem;
}

.search-field-container {
	margin-top: 60px;
	margin-bottom: 55px;
	width: 500px;
	display: inline-block;
}

.search-field-container-mobile {
	margin-top: 20px;
	margin-bottom: 30px;
	display: inline-block;
	max-width: 85vw;
}

.search-field-input-group {
	margin-left: auto;
	margin-right: auto;
	transition: 0.3s;
}

.search-field-input-group-mobile {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 45px;
	transition: 0.3s;
}

.search-field-text-title-mobile {
	color: white;
	font-size: 1.7rem;
	margin-bottom: 10px;
}

.search-field-input-group:hover {
  transform: translate(0px, -2px);
}

.search-field-form-control {
	width: 250px;
}

.search-field-length-feedback {
	height: 9px !important;
  text-align: right !important;
  margin-top: 1px !important;
  line-height: 1rem !important;
  color: #FFFFFF !important;
}

.search-field-length-feedback-mobile {
	height: 9px !important;
  text-align: left !important;
  margin-top: 1px !important;
  line-height: 1rem !important;
  color: #FFFFFF !important;
  padding-left: 60px !important;
}

.search-field-no-match-feedback {
	height: 9px !important;
  text-align: right !important;
  margin-top: 1px !important;
  line-height: 1rem !important;
  color: #ff4444 !important;
}

.search-field-no-match-feedback-mobile {
	height: 9px !important;
  text-align: left !important;
  margin-top: 1px !important;
  line-height: 1rem !important;
  color: #ff4444 !important;
  padding-left: 60px !important;
}


.navbar-desktop {
	background-color: #212121;
	padding-right: 2px;
	padding-top: 2px;
	padding-left: 10px;
}

.navbar-mobile {
	background-color: #212121;
	padding-right: 2px;
	padding-left: 2px;
	padding-top: 4px;
	padding-bottom: 2px;
}

.navbar-brand-img {
	transition: 0.3s;
}

.navbar-brand-img:hover {
	transform: translate(0px, -2px);
}

.header-nav-link-selected { 
	color: white !important;
	font-size: 1.4rem !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
	padding-top: 20px !important;
	margin-left: 8px !important;
	margin-right: 8px !important;
	border-bottom-style: solid !important;
  border-bottom-width: medium !important;
  transition: 0.3s;
}

.header-nav-link-selected:hover {
	color: grey !important;
	border-bottom-style: solid !important;
  border-bottom-width: medium !important;
  transform: translate(0px, -2px);
}

.header-nav-link-hover { 
	color: white !important;
	font-size: 1.4rem !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
	padding-top: 20px !important;
	margin-left: 5px !important;
	margin-right: 5px !important;
	transition: 0.3s;
}

.header-nav-link-hover:hover {
	color: grey !important;
	transform: translate(0px, -2px);
}

.input-group-prepend > .btn {
	font-size: 1.15rem !important;
	padding-left: 8px !important;
	padding-right: 8px !important;
}

.popover-header {
	background-color: #212121 !important;
	color: white;
}

.popover-body {
	padding: 0 !important;
}

.input-group > .input-group-append:not(:last-child) > .btn {
	border-color: white;
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.input-group > .form-control,
.input-group > .form-control:focus {
  background-color: transparent;
  color: white;
  box-shadow: none;
  border-color: white;
}

.form-control.is-valid,
.form-control.is-invalid,
.form-control.is-valid:focus,
.form-control.is-invalid:focus {
  border-color: white;
  background-image: none;
}

.form-control ~ .valid-feedback,
.form-control:focus ~ .valid-feedback,
.form-control ~ .invalid-feedback,
.form-control:focus ~ .invalid-feedback {
  height: 9px;
  text-align: left;
  margin-top: 1px;
  line-height: 1rem;
  padding-left: 100px;
  color: #ff4444;
}

.galaxy-item { 
	font-size: 1rem; 
	display: flex;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	align-items: center;
	background-color: white;
}

.selected-galaxy-item { 
	font-size: 1rem; 
	display: flex;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	align-items: center;
	background-color: #343a40 !important;
	color: white !important;
}

.galaxy-popover { 
	max-height: 50vh;
	overflow-y: scroll;
	-webkit-overflow-scrolling: auto;
	overflow-scrolling: auto;
}

.galaxy-popover-mobile { 
	max-height: 50vh;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
}

.stats-summary-spinner {
	padding-right: 30px;
	padding-top: 5px;
}

.stats-summary-table-container {
	margin-top: 2px;
	text-align: right;
}

.stats-summary-table-header-row {
	text-align: center;
	color: grey !important;
	border-style: none !important;
	padding-top: 4px !important;
	padding-bottom: 2px !important;
	padding-left: 5px !important;
	padding-right: 8px !important;
	font-size: 0.7rem;
}

.stats-summary-table-data-row {
	color: white;
	font-size: 0.95rem;
	padding-left: 4px !important;
	padding-right: 4px !important;
	padding-top: 4px !important;
	padding-bottom: 4px !important;
	line-height: 0.8rem;
	text-align: center;
	vertical-align: middle;
	border-style: none !important;
}

.stats-summary-table-data-infinity {
	color: #00C853;
	font-size: 1.4rem; 
	vertical-align: middle;
}

.stats-summary-currency-button-group {
	margin-top: 4px;
	padding-top: 3px;
  padding-bottom: 3px;
  margin-right: 10px;
}

.stats-summary-currency-button {
	box-shadow: none !important;
	font-size: 1rem;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 1px; 
	padding-bottom: 1px;
}

.stats-summary-currency-button-bitcoin {
	font-size: 1.1rem;
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 1px; 
	padding-bottom: 1px;
}

.dropdown-menu .sub-menu {
  left: 100%;
  position: absolute;
  top: 0px;
  visibility: hidden;
  margin-top: 0px;
}

.dropdown-menu li:hover .sub-menu {
  visibility: visible;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.nav-tabs .dropdown-menu,
.nav-pills .dropdown-menu,
.navbar .dropdown-menu {
  margin-top: 0;
}

.header-nav-dropdown-item {
  font-size: 1.3rem;
}


.buy-star-banner {
	display: inline-block; 
	margin-left: auto; 
	margin-right: auto; 
	margin-top: 10px;
}

.buy-star-banner-mobile {
	margin-left: auto; 
	margin-right: auto; 
	margin-top: 10px;
	max-width: 350px;
}

.search-results-subtitle {
	color: white !important; 
	margin-top: 15px;
	font-size: 0.85rem;
}

.buy-planet-deck {
  display: inline-flex;
  align-items: center;
  color: white;
  margin-left: auto; 
	margin-right: auto; 
}

.buy-planet-deck-mobile {
  display: inline-flex;
  color: white;
  flex-direction: row;
  margin-left: auto; 
	margin-right: auto; 
}

.buy-planet-deck-top {
	margin-top: 20px !important;
	margin-bottom: 10px !important;
}

.buy-planet-deck-other {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.buy-planet-deck-bottom {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.buy-planet-deck-bottom-straggler {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  margin-left: 50px !important;
	margin-right: 50px !important;
}

.buy-planet-card {
	cursor: pointer !important;
	background-color: #2E2E2E;
	max-width: 180px !important;
	margin-left: 10px !important;
	margin-right: 10px !important;
	transition: 0.3s;
}

.buy-planet-card-mobile {
	cursor: pointer !important;
	background-color: #2E2E2E;
	max-width: 180px !important;
	margin-left: 6px !important;
	margin-right: 6px !important;
	margin-bottom: 0px !important;
}

.buy-planet-card-mobile-link {
	color: white !important;
	text-decoration: none !important;
}

.buy-planet-card:hover {
  transform: translate(0px, -2px);
}

.buy-planet-card-selected {
	min-width: 380px !important;
}

.buy-planet-card-content-container {
	display: flex;
	text-align: left;
}

.buy-planet-card-header {
	padding-right: 6px;
	padding-left: 6px;
	font-size: 1.1rem !important;
	color: white !important;
}

.buy-planet-card-selected-header {
	padding-right: 5px;
	padding-left: 5px;
	font-size: 1.5rem !important;
}

.buy-planet-card-sigil-container {
	margin-left: 10px;
	margin-right: 10px;
}

.buy-planet-card-sigil-container-mobile {
	margin-top: 4px;
	margin-bottom: 4px;
	margin-left: 12px;
	margin-right: 12px;
}

.buy-planet-card-info-container {
	margin: 10px;
	padding-top: 10px;
}

.buy-planet-card-info-container-mobile {
	margin: 8px;
}

.buy-planet-card-sponsor-link {
	margin-left: 10px;
	color: white !important;
	text-decoration: none !important;
}

.buy-planet-card-sponsor-link:hover {
	color: grey !important;
	text-decoration: none !important;
}

.buy-planet-card-learn-more-button {
	font-size: 1.3rem !important;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 10px;
	padding-top: 10px;
	color: white !important;
	background-color: #212121 !important;
	display: inline-block !important;
	margin-top: 5px;
	margin-right: 10px;
	border-radius: 0.3rem !important;
	text-decoration: none !important;
}

.buy-planet-card-buy-button {
	font-size: 1.3rem !important;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 8px;
	padding-top: 8px;
	color: white !important;
	background-color: #00C853 !important;
	margin-top: 3px;
	margin-bottom: 10px;	
	margin-left: 10px;
	border-color: transparent;
}

.buy-planet-card-buy-button:hover {
	border-color: #212121;
}

.buy-planet-card-connect-button {
	font-size: 1.3rem !important;
	padding-left: 12px;
	padding-right: 12px;
	padding-bottom: 8px;
	padding-top: 8px;
	color: white !important;
	margin-top: 3px;
	margin-bottom: 10px;	
	margin-left: 10px;
	border-color: transparent;
}

.x-button { 
	position: absolute;
	left: 5px;
	top: 5px;
	padding: 0;
	line-height: 1;
	color: white;
	font-size: large;
}

.x-button:hover { 
	color: grey !important;
}

.buy-planet-table-container {
	margin: 10px;
}

.buy-planet-table-header-cell {
	color: white !important;
	font-size: 1.5rem;
	font-weight: 600;
	border-top-color: transparent !important;
	padding-bottom: 5px !important;
}

.buy-planet-table-header-button {
	color: white !important;
	font-size: 1.5rem;
	font-weight: 600;
	border-top-color: transparent !important;
	padding-bottom: 5px !important;
}

.buy-planet-table-sigil-row {
	color: white !important;
	border-style: none !important;
	text-align: left !important;
	text-decoration: none !important;
	font-size: 1.3rem;
	vertical-align: middle !important;
}

.buy-planet-table-sigil-container {
	margin-left: 5px;
	margin-right: 25px;
}

.buy-planet-table-name {
	margin-top: auto;
	margin-bottom: auto;
}

.buy-planet-table-data-row {
	color: white !important;
	font-size: 1.3rem;
	border-style: none !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	text-align: center !important;
	vertical-align: middle !important;
}

.buy-planet-table-buy-button {
	font-size: 1.3rem !important;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 8px;
	padding-top: 8px;
	color: white !important;
	background-color: #00C853 !important;
	border-color: transparent !important;
	cursor: pointer;
}

.buy-planet-table-buy-button:hover {
	border-color: #212121 !important;
}

.buy-planet-table-link {
	color: white !important;
	text-decoration: none !important;
	display: inline-flex !important;
	vertical-align: middle !important;
}

.buy-planet-table-link:hover {
	color: grey !important;
}

.buy-planet-table-container-mobile {
	margin-right: auto;
	margin-left: auto;
}

.buy-planet-table-header-cell-mobile {
	color: white !important;
	font-size: 1rem;
	font-weight: 600;
	border-top-color: transparent !important;
	padding-bottom: 5px !important;
}

.buy-planet-table-header-button-mobile {
	color: white !important;
	font-size: 1rem;
	font-weight: 600;
	border-top-color: transparent !important;
	padding-bottom: 5px !important;
	width: 1%;
}

.buy-planet-table-sigil-row-mobile {
	color: white !important;
	display: inline-flex;
	border-style: none !important;
	text-decoration: none !important;
	padding-left: 4px !important;
	padding-right: 4px !important;
	font-size: 0.92rem;
	vertical-align: middle !important;
}

.buy-planet-table-sigil-container-mobile {
	font-size: 0.95rem;
	margin-left: 4px;
	margin-right: 8px;
}

.buy-planet-table-data-row-mobile {
	color: white !important;
	font-size: 0.9rem;
	border-style: none !important;
	padding-left: 4px !important;
	padding-right: 4px !important;
	text-align: center !important;
	vertical-align: middle !important;
}

.buy-planet-table-link-mobile {
	color: white !important;
	text-decoration: none !important;
	display: inline-flex !important;
}

.buy-planet-table-link-mobile:hover {
	color: grey !important;
}

.buy-planet-table-buy-button-mobile {
	font-size: 0.95rem !important;
	padding-left: 8px !important;
	padding-right: 8px !important;
	padding-bottom: 5px;
	padding-top: 5px;
	margin-right: 4px;
	background-color: #00C853 !important;
	border-color: transparent !important;
}

.buy-planet-table-buy-button-mobile:hover {
	border-color: #212121 !important;
}

.search-suggestion-button {
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 20px;
	margin-top: 20px;
}

.search-suggestion-button-mobile {
	margin-left: 8px;
	margin-right: 8px;
	margin-bottom: 10px;
	margin-top: 10px;
}


.buy-control-panel-container {
	display: block;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 0.3rem !important;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 10px;
	background-color: #2E2E2E;
	max-width: 1000px;
}

.sort-by-dropdown {
	box-shadow: none !important;
}

.sort-by-dropdown-item {
	font-size: 1rem;
}

.sort-by-dropdown-item-selected {
	font-weight: bolder;
	font-size: 1.2rem;
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-button-group {
	margin-left: 10px;
	margin-right: auto;
}

.buy-help-button {
	margin-left: auto !important;
	margin-right: 10px;
	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 8px;
	padding-right: 8px;
	font-size: 1.4rem;
	box-shadow: none;
	background-color: transparent;
	outline: none;
	border-style: none;
	cursor: pointer;
}

.list-layout-button {
	box-shadow: none !important;
	cursor: pointer;
}

.grid-layout-button {
	box-shadow: none !important;
	cursor: pointer;
}

.list-star-button {
	box-shadow: none !important;
	cursor: pointer;
	margin-left: 10px;
	margin-right: 10px;
}

.layout-button-group {
	margin-left: 10px;
	margin-right: 10px;
}

.control-panel-title-buy {
	color: white !important; 
	font-size: 1.65rem;
	margin-left: auto;
	margin-right: auto;
}

.control-panel-title-search {
	color: white !important; 
	font-size: 1.5rem;
	margin-left: 20px;
	margin-right: 20px;
}


.star-banner {
	display: inline-block; 
	margin-left: auto; 
	margin-right: auto; 
	margin-top: 10px;
	margin-bottom: 10px;
}

.star-banner-mobile {
	margin-left: auto; 
	margin-right: auto; 
	margin-top: 10px;
	max-width: 350px;
}

.planet-deck {
  display: inline-flex;
  align-items: center;
  color: white;
}

.planet-deck-mobile {
  display: inline-flex;
  color: white;
  flex-direction: row;
}

.planet-deck-top {
	margin-top: 20px !important;
	margin-bottom: 10px !important;
}

.planet-deck-other {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.planet-deck-bottom {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.planet-card {
	max-width: 180px !important;
	cursor: pointer;
	margin-left: 10px !important;
	margin-right: 10px !important;
	transition: 0.3s;
	background-color: #2E2E2E;
}

.planet-card:hover {
  transform: translate(0px, -2px);
}

.planet-card-mobile {
	max-width: 180px !important;
	cursor: pointer;
	margin-left: 6px !important;
	margin-right: 6px !important;
	background-color: #2E2E2E;
	margin-bottom: 0px !important;
}

.planet-card-sigil-container {
	margin-left: 10px;
	margin-right: 10px;
}

.planet-card-single {
	margin-left: auto !important;
	margin-right: auto !important;
}

.planet-card-selected {
	width: 15vw !important;
	margin: 1vmin !important;
	min-width: 260px !important;
	max-width: 275px !important;
}

.planet-card-header {
	padding-right: 6px;
	padding-left: 6px;
	font-size: 1.1rem !important;
}

.planet-card-footer {	
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.planet-card-footer-info {
	text-align: left;
	margin-left: 10px !important;
	padding-left: 0px !important;
	padding-top: 2px !important;
	padding-bottom: 2px !important;
}

.planet-card-link {
	color: white !important;
	text-decoration: none !important;
}

.planet-purchase-button {
	font-size: 1.2rem !important;
	font-weight: bold !important;
	padding-left: 4px !important;
	padding-right: 4px !important;
	color: white !important;
	background-color: #28A745 !important;
	width: 235px !important;
}

.planet-info-btn {
	font-size: 1.2rem !important;
	line-height: 1.5 !important;
	padding-left: 0.5rem !important;
	padding-right: 0.5rem !important;
	padding-bottom: 0.5rem !important;
	padding-top: 0.5rem !important;
	color: white !important;
	background-color: #212121 !important;
	width: 170px !important;
	display: inline-block !important;
	margin-top: 15px !important;
	margin-bottom: 10px !important;	
	border-radius: 0.3rem !important;
	text-decoration: none !important;
}

.x-button { 
	position: absolute;
	left: 1vmin;
	top: 1vmin;
	padding: 0;
	line-height: 1;
	color: white;
	font-size: large;
}

.x-button:hover { 
	color: grey !important;
}

.loading-bar {
	margin: 25px;
	min-width: 100px;
}

.galaxy-list-item-container {
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 20vw;
	margin-left: 20vw;
}

.galaxy-list-item {
	transition: 0.3s;
}

.galaxy-list-item:hover {
	transform: translate(0px, -3px);
}

.galaxy-list-item-column {
	margin-left: 5px;
	margin-right: 5px;
	flex-grow: 0;
}

.search-suggestion-button {
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 20px;
	margin-top: 20px;
	background-color: #2E2E2E !important;
}

.search-suggestion-button-mobile {
	margin-left: 8px;
	margin-right: 8px;
	margin-bottom: 10px;
	margin-top: 10px;
}


.purchase-button {
	font-size: 1.3rem !important;
	font-weight: bold !important;
	padding-left: 16px !important;
	padding-right: 16px !important;
	color: white !important;
	background-color: #00C853 !important;
	border-color: transparent !important;
}

.purchase-button-mobile {
	font-size: 1.1rem !important;
	font-weight: bold !important;
	padding-left: 12px !important;
	padding-right: 12px !important;
	color: white !important;
	background-color: #00C853 !important;
	border-color: transparent !important;
}

.purchase-button-no-wallet {
	font-size: 1.4rem !important;
	font-weight: bold !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
	color: white !important;
	background-color: #00C853 !important;
	border-color: transparent !important;
}

.purchase-button:hover {
	border-color: black !important;
}

.purchase-button-mobile:active {
	border-color: black !important;
}

.purchase-button-mobile:hover {
	border-color: black !important;
}

.purchase-button-no-wallet:disabled {
	border-color: transparent !important;
}

.purchase-button-no-wallet:hover {
	border-color: #212121 !important;
}

.no-wallet-notice {
	font-size: 1rem !important;
	text-align: center !important;
	color: white !important;
	display: block;
	margin-top: 5px;
	margin-bottom: 4px;
}

.buttons-container {
	margin-top: 5px;
	margin-bottom: 5px;
}

.download-sigil-button {
	font-size: 0.8rem !important;
	color: white !important;
	background-color: #676370 !important;
	margin-right: 3px;
	padding-top: 9px;
	padding-left: 10px;
	padding-right: 9px;
	padding-bottom: 9px;
	text-decoration: none !important;
	border-style: none !important;
}

.copy-sigil-button {
	font-size: 0.8rem !important;
	color: white !important;
	background-color: #676370 !important;
	margin-right: 3px;
	padding-top: 6px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 6px;
	text-decoration: none !important;
	border-color: transparent !important;
}

.share-button {
	font-size: 0.8rem !important;
	color: black !important;
	margin-top: -1px !important;
	background-color: white !important;
	margin-left: 3px;
	padding-top: 6px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 6px;
	border-color: transparent !important;
}

.download-sigil-button:hover {
	box-shadow: inset 1px 1px 1px #212121;
}

.share-button:hover {
	border-color: #212121 !important;
}

.data-row,
.data-row-link {
	color: white !important;
	font-size: 1.3rem;
	border-style: none !important;
}

.data-row-link:hover {
	color: grey !important;
	text-decoration: none !important;
}

.buy-row-no-wallet {
	padding-bottom: 4px !important;
}

.point-row {
	cursor: pointer;
	line-height: 1.5;
}

.keyrev-row {
	line-height: 1.5;
}

.owner-row {
	word-break: break-all;
	line-height: 1.5;
	font-size: 1.2rem !important;
}

.point-copy-button {
	color: #999999;
	font-size: 1rem;
	margin-left: 5px;
	padding-top: 0px;
	background-color: transparent;
	border-color: transparent;
	outline: none !important;
	cursor: pointer !important;
}

.point-copy-button:hover { 
	color: #595959;
}

.point-copied {
	color: #787878;
	font-size: 1.1rem;
}

.spawned-deck {
	min-height: 10vh;
  display: inline-flex;
  align-items: center;
  justify-content: normal;
  color: white;
}

.spawned-deck-mobile {
	min-height: 10vh;
  display: inline-flex;
  align-items: center;
  justify-content: normal;
  margin-bottom: 0.5vmin;
  color: white;
}

.spawned-deck-top {
	margin-top: 30px !important;
	margin-bottom: 15px !important;
}

.spawned-deck-other {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.spawned-deck-bottom {
	margin-top: 15px !important;
  margin-bottom: 30px !important;
}

.spawned-card-link:hover {
	text-decoration: none !important;
}

.spawned-card {
	min-width: 175px !important;
	cursor: pointer;
	margin-left: 15px !important;
	margin-right: 15px !important;
	background-color: #2E2E2E;
	transition: 0.3s;
}

.spawned-card:hover {
	transform: translate(0px, -2px);
}

.spawned-card-header {
	padding: 5px !important;
	margin: 5px !important;
	font-size: 1.1rem !important;
	color: white;
}

.ship-view-card-sigil-container {
	margin-left: 10px;
	margin-right: 10px;
}

.complete-list-planet-card {
	min-width: 180px !important;
	cursor: pointer;
	margin-left: 12px !important;
	margin-right: 12px !important;
	background-color: #2E2E2E;
	transition: 0.3s;
}

.complete-list-planet-card:hover {
	transform: translate(0px, -2px);
}

.complete-list-planet-card-selected {
	width: 15vw !important;
	margin: 1vmin !important;
	min-width: 260px !important;
	max-width: 275px !important;
}

.planet-list-tab-link:hover {
	border-style: none !important;
	color: grey !important;
}

.planet-list-tab-link:active {
	color: #212121 !important;
}

.planet-list-tab-link {
	font-size: 1.2rem !important;
	color: white;
}

.breadcrumb {
  background-color: transparent !important;
  margin-bottom: 5px !important;
}

.breadcrumb a:hover {
  color: grey !important;
	text-decoration: none !important;
}

ol > li > a {
	color:white
}

.ship-sigil {
	display: inline-block !important;
	margin-left: auto !important;
	margin-bottom: 0px !important;
	margin-right: 4px;
}

.color-tile-container {
	padding: 0;
	height: 12px;
}

.color-tile {
	height: 12px;
	margin: 0;
	border-color: transparent !important;
	border-style: none !important;
	border-radius: 0;
	vertical-align: top;
	transition: 0.3s;
}

.color-tile:focus {
	box-shadow: none !important;
}

.color-tile:hover {
	transform: translate(0px, -2px);
}

.page-link {
	color: #212121 !important;
}

.page-item.active > .page-link {
	color: white !important;
	background-color: #212121 !important;
	border-color: white !important;
}


.points-table-container { 
	margin-right: 0px; 
	margin-left: 0px; 
	margin-top: 5px;
	margin-bottom: 10px;
	display: inline-block !important;
}

.points-table-container-mobile { 
	margin-right: auto; 
	margin-left: auto; 
	margin-top: 5px;
	margin-bottom: 10px;
	display: inline-block !important;
}

.points-table-header-row {
	color: white !important;
	border-top-color: transparent !important;
	border-bottom-color: transparent !important;
	padding-bottom: 2px !important;
}

.points-table-row {
	padding-left: 5px;
	border-style: none;
}

.points-table-row-mobile {
	line-height: 10px;
}

.points-table-ship-data {
	text-align: left;
	padding-top: 10px !important;
	padding-bottom: 5px !important;
}

.points-table-ship-data-mobile {
	text-align: left;
	padding: 0.5rem;
}

.points-table-row-data {
	text-align: center;
	padding-top: 17px !important;
}

.points-table-row-data-mobile {
	text-align: center;
	vertical-align: middle !important;
	padding: 5px !important;
}

.points-row-date {
	color: white;
	font-size: 1.7rem;
	text-align: right;
	font-weight: 600;
	margin-right: 20px;
}

.points-row-price {
	color: white;
	margin-top: 5px;
	margin-right: 20px;
	font-size: 1.5rem;
}

.points-row-sigil {
	display: inline-flex;
	margin-left: 5px;
	margin-right: 5px;
}

.points-row-sigil-mobile {
	display: inline-flex;
	margin-left: 2px;
	margin-right: 2px;
}

.points-row-name {
  color: white;
  margin-left: 10px;
  padding-top: 8px;
}

.points-row-name-mobile {
  color: white;
  margin-left: 10px;
  padding-top: 15px;
}

.points-row-point {
  color: white;
  padding-top: 8px;
}

.points-row-parent {
  color: white;
  padding-top: 3px;
}

.points-row-mobile-dateprice {
	color: white;
	margin-right: 20px;
	margin-left: auto;
	margin-top: 15px;
	display: flex;
}

.points-row-date-mobile {
	color: white;
	text-align: right;
	font-size: 1.3rem;
	font-weight: 600;
	margin-right: 10px;
}

.points-row-price-mobile {
	color: white;
	text-align: right;
	font-size: 1.3rem;
	margin-left: 10px;
}

.points-link {
	padding-left: 10px;
	margin-top: 5px;
	color: darkgray;
	font-size: 0.8rem;
	text-decoration: none;
}

.points-link:hover {
	color: gray;
}

.points-link:hover {
	text-decoration: none;
}

.points-link-icon {
	margin-left: 5px;
	margin-bottom: 2px;
	font-size: 0.75rem;
	vertical-align: middle;
}

.stats-control-panel-container {
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 0.3rem !important;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 10px;
	background-color: #2E2E2E;
	max-width: 1000px;
}

.stats-control-panel-container-mobile {
	padding-top: 0px;
	display: block;
	border-radius: 0.3rem !important;
	background-color: #2E2E2E;
	margin-bottom: 25px;
}

.stats-control-panel-top-row-mobile {
	display: inline-block; 
	padding-top: 5px; 
	margin-right: 5px; 
	margin-left: 5px;
}

.stats-control-panel-second-row-mobile {
	display: inline-block; 
	padding-bottom: 10px; 
	margin-right: 5px; 
	margin-left: 5px;
}

.stats-date-picker-button-group {
	margin-left: 10px;
	margin-right: auto;
	padding-top: 5px;
}

.start-date-picker-button {
	margin-right: 5px;
}

.end-date-picker-button {
	margin-left: 5px;
}

.stats-date-picker-button-group-mobile {
	margin-left: auto;
	margin-right: auto;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-bottom: 5px;
}

.start-date-picker-button-mobile {
	margin-right: 8px;
}

.end-date-picker-button-mobile {
	margin-left: 8px;
}

.btn-lg#chart-dropdown {
  font-size: 1.5rem;
  padding-top: 5px;
  padding-bottom: 5px;
}

.btn-sm#chart-dropdown {
  font-size: 1rem;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 10px;
	padding-right: 10px;
}

.scale-button-group {
	margin-left: auto;
	margin-right: 10px;
	padding-left: 65px;
	padding-top: 3px;
  padding-bottom: 3px;
}

.scale-button {
	box-shadow: none !important;
	margin: 0;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 12px;
}

.scale-button-group-mobile {
  margin-left: 10px;
	margin-right: 10px;
}

.scale-button-mobile {
	box-shadow: none !important;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 12px;
	padding-top: 6px;
}

.scale-button-img {
	height: 20px;
	width: 20px;
}

.scale-button-img-mobile {
	height: 20px;
	width: 20px;
}

.currency-button-group {
	margin-left: 0px;
	margin-right: 10px;
	padding-top: 3px;
  padding-bottom: 3px;
}

.currency-button-group-mobile {
	margin-right: auto;
}

.currency-button {
	box-shadow: none !important;
}

.currency-button-bitcoin {
	font-size: 1.5rem;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 2px; 
	padding-bottom: 2px;
}

.currency-button-mobile {
	box-shadow: none !important;
	font-size: 1rem;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: 5px;
}

.currency-button-bitcoin-mobile {
	font-size: 1.3rem;
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 2px; 
	padding-bottom: 2px;
}

.graph-tooltip {
	width: 200px;
	margin: 0;
	background-color: #FFFFFF;
	padding: 5px;
	padding-bottom: 2px;
}

.tooltip-date-price-container {
	display: flex;
	margin-bottom: 1px;
}

.tooltip-date {
	line-height: 1rem;
	margin-top: 5px;
	margin-left: auto;
	margin-right: 10px;
	font-size: 1.1rem;
	font-weight: 600;
}

.tooltip-price {
	line-height: 1rem;
	margin-top: 5px;
	margin-right: auto;
	margin-left: 10px;
	font-size: 1.1rem;
	font-weight: 600;
}

.tooltip-name-sigil-container {
	display: flex;
	margin-bottom: 1px;
	margin-top: 1px;
	margin-left: 0px;
}

.tooltip-sigil-container {
	margin-left: auto;
	margin-right: 5px;
}

.tooltip-name {
	padding-top: 6px;
	padding-left: 2px;
	padding-right: 2px;
	font-size: 1rem;
	margin-left: 5px;
	margin-right: auto;
	text-align: left;
	color: #212529;
	background-color: transparent;
	border-color: transparent;
}

.tooltip-others-container {
	margin-top: 0px;
	margin-bottom: 0px;
}

.tooltip-others {
	margin-top: 1px;
	text-align: center;
	margin-bottom: 3px;
	line-height: 1;
}

.tooltip-tip {
	font-size: 0.6rem;
	margin-bottom: 1px;
	margin-top: 1px;
}

.spawn-tooltip {
	width: 180px;
	margin: 0;
	text-align: left;
	background-color: #FFFFFF;
	padding: 10px;
	padding-right: 5px;
}

.spawn-tooltip-date {
	line-height: 1rem;
	margin-top: 3px;
	margin-bottom: 3px;
	margin-left: 5px;
	margin-right: auto;
}

.spawn-tooltip-count {
	line-height: 1rem;
	margin-top: 3px;
	margin-bottom: 3px;
	margin-left: auto;
	margin-right: 5px;
}

.stats-snapshot-container {
	display: flex;
	color: white;
	margin-top: 2px;
	margin-bottom: 5px !important;
	line-height: 1rem;
}

.stats-snapshot-volume {
	margin-left: auto;
	margin-right: 15px;
	margin-bottom: 2px !important;
}

.stats-snapshot-count {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2px !important;
}

.stats-snapshot-average {
	margin-left: 15px;
	margin-right: auto;
	margin-bottom: 2px !important;
}

.stats-snapshot-container-mobile {
	display: contents;
	color: white;
	margin-bottom: 5px !important;
}

.stats-snapshot-volume-mobile {
	margin-left: auto;
	margin-right: 5px;
	margin-bottom: 3px;
	margin-top: 5px;
}

.stats-snapshot-count-mobile {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 3px;
	margin-top: 5px;
}

.stats-snapshot-average-mobile {
	margin-left: 5px;
	margin-right: auto;
	margin-bottom: 3px;
	margin-top: 5px;
}

.react-datepicker__triangle { 
	margin-left: 4em !important; 
}


.help-letter-container {
	max-width: 60%;
	margin-top: 30px; 
	margin-left: auto; 
	margin-right: auto;
	padding-top: 50px;
	padding-bottom: 15px;
}

.help-letter-container-mobile {
	margin-top: 15px; 
	margin-left: 4%;
	margin-right: 4%;
	padding-top: 40px;
	padding-bottom: 15px;
}

.help-letter { 
	padding-left: 40px;
	padding-right: 40px;
	text-align: left;
	font-size: 1.1rem;
}

.help-letter-mobile { 
	text-align: left;
	font-size: 1.1rem;
}

.checklist-container {
	text-align: center;
	margin-top: 15px;
}

.checklist-item {
	font-size: 1.2rem;
	margin-top: 5px;
}

a:hover {
	color: grey !important;
	text-decoration: none !important;
}

.contact-footer {
	background-color: #212121 !important;
	bottom: 0px;
	margin-top: 5px;
	width: 100%;
}

.footer-container {
	padding-top: 5px;
	padding-bottom: 5px;
	display: inline-flex;
	max-width: none;
	padding-right: 0px;
	padding-left: 0px;
}

.footer-container-mobile {
	padding-right: 0px;
	padding-left: 0px;
	display: inline-flex;
	padding-top: 5px;
	padding-bottom: 5px;
}

.help-button {
	padding-top: 4px;
  padding-right: 8px;
  color: white;
	background-color: transparent;
	border-style: none;
	font-size: 1.2rem;
	margin-left: 10px;
	margin-right: 10px;
	cursor: pointer;
	outline: none !important;
	transition: 0.3s;
}

.help-button:hover {
	color: grey !important;
	text-decoration: none !important;
	transform: translate(0px, -2px);
}

.help-button-mobile {
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  color: white;
	background-color: transparent;
	border-style: none;
	font-size: 1.2rem;
	margin-left: 10px;
	margin-right: 10px;
	line-height: 1.75em;
}

.contact-button {
	color: white;
	background-color: #212121 !important;
	border-style: none;
	font-size: 1.25rem !important;
	padding-left: 5px;
	padding-right: 5px;
	margin-left: 5px;
	margin-right: 5px;
}

.contact-button-mobile {
	color: white;
	background-color: #212121 !important;
	border-style: none;
	padding-top: unset;
	line-height: 1.75em;
}

.email-group {
	display: flex;
	margin-top: 5px;
	margin-right: 8px;
}

.email-group-mobile {
	display: flex;
	margin-top: 5px;
	margin-right: 5px;
}

.email-address {
	color: white;
	font-size: 1.1rem;
	margin-top: 1px;
	margin-right: 2px;
	margin-left: 10px;
	margin-bottom: 5px;
}

.email-address-copied {
	color: #787878;
	font-size: 1.1rem;
	margin-right: 48px;
	margin-left: 48px;
	margin-bottom: 6px;
	/*padding-top: 0px;*/
	/*padding-bottom: 0px;*/
}

.copy-button {
	color: #999999;
	height: 25px;
	margin-right: 2px;
	margin-left: 0px;
	padding-top: 3px;
	background-color: transparent;
	border-style: none;
	outline: none !important;
	cursor: pointer !important;
	transition: 0.3s;
}

.copy-button:hover { 
	color: #595959;
	transform: translate(0px, -1px);
}

.icon-group {
	margin-right: 0px;
	margin-left: auto;
}

.icon-group-mobile {
	margin-right: auto;
	margin-left: auto;
}

.media-icon {
	margin-left: 15px;
	margin-right: 15px;
  color: white;
  font-size: 1.5rem;
  transition: 0.3s;
}

.media-icon:hover { 
	color: grey;
	transform: translate(0px, -2px);
}

.media-icon-mobile {
	margin-left: 12px;
	margin-right: 12px;
  color: white;
  font-size: 1.5rem;
  transition: 0.3s;
}

.UrbitLive {
  text-align: center;
  height: 100vh;
  background-color: #212121;
  font-family: 'Karla';
}

