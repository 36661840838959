
.points-table-container { 
	margin-right: 0px; 
	margin-left: 0px; 
	margin-top: 5px;
	margin-bottom: 10px;
	display: inline-block !important;
}

.points-table-container-mobile { 
	margin-right: auto; 
	margin-left: auto; 
	margin-top: 5px;
	margin-bottom: 10px;
	display: inline-block !important;
}

.points-table-header-row {
	color: white !important;
	border-top-color: transparent !important;
	border-bottom-color: transparent !important;
	padding-bottom: 2px !important;
}

.points-table-row {
	padding-left: 5px;
	border-style: none;
}

.points-table-row-mobile {
	line-height: 10px;
}

.points-table-ship-data {
	text-align: left;
	padding-top: 10px !important;
	padding-bottom: 5px !important;
}

.points-table-ship-data-mobile {
	text-align: left;
	padding: 0.5rem;
}

.points-table-row-data {
	text-align: center;
	padding-top: 17px !important;
}

.points-table-row-data-mobile {
	text-align: center;
	vertical-align: middle !important;
	padding: 5px !important;
}

.points-row-date {
	color: white;
	font-size: 1.7rem;
	text-align: right;
	font-weight: 600;
	margin-right: 20px;
}

.points-row-price {
	color: white;
	margin-top: 5px;
	margin-right: 20px;
	font-size: 1.5rem;
}

.points-row-sigil {
	display: inline-flex;
	margin-left: 5px;
	margin-right: 5px;
}

.points-row-sigil-mobile {
	display: inline-flex;
	margin-left: 2px;
	margin-right: 2px;
}

.points-row-name {
  color: white;
  margin-left: 10px;
  padding-top: 8px;
}

.points-row-name-mobile {
  color: white;
  margin-left: 10px;
  padding-top: 15px;
}

.points-row-point {
  color: white;
  padding-top: 8px;
}

.points-row-parent {
  color: white;
  padding-top: 3px;
}

.points-row-mobile-dateprice {
	color: white;
	margin-right: 20px;
	margin-left: auto;
	margin-top: 15px;
	display: flex;
}

.points-row-date-mobile {
	color: white;
	text-align: right;
	font-size: 1.3rem;
	font-weight: 600;
	margin-right: 10px;
}

.points-row-price-mobile {
	color: white;
	text-align: right;
	font-size: 1.3rem;
	margin-left: 10px;
}

.points-link {
	padding-left: 10px;
	margin-top: 5px;
	color: darkgray;
	font-size: 0.8rem;
	text-decoration: none;
}

.points-link:hover {
	color: gray;
}

.points-link:hover {
	text-decoration: none;
}

.points-link-icon {
	margin-left: 5px;
	margin-bottom: 2px;
	font-size: 0.75rem;
	vertical-align: middle;
}

.stats-control-panel-container {
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 0.3rem !important;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 10px;
	background-color: #2E2E2E;
	max-width: 1000px;
}

.stats-control-panel-container-mobile {
	padding-top: 0px;
	display: block;
	border-radius: 0.3rem !important;
	background-color: #2E2E2E;
	margin-bottom: 25px;
}

.stats-control-panel-top-row-mobile {
	display: inline-block; 
	padding-top: 5px; 
	margin-right: 5px; 
	margin-left: 5px;
}

.stats-control-panel-second-row-mobile {
	display: inline-block; 
	padding-bottom: 10px; 
	margin-right: 5px; 
	margin-left: 5px;
}

.stats-date-picker-button-group {
	margin-left: 10px;
	margin-right: auto;
	padding-top: 5px;
}

.start-date-picker-button {
	margin-right: 5px;
}

.end-date-picker-button {
	margin-left: 5px;
}

.stats-date-picker-button-group-mobile {
	margin-left: auto;
	margin-right: auto;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-bottom: 5px;
}

.start-date-picker-button-mobile {
	margin-right: 8px;
}

.end-date-picker-button-mobile {
	margin-left: 8px;
}

.btn-lg#chart-dropdown {
  font-size: 1.5rem;
  padding-top: 5px;
  padding-bottom: 5px;
}

.btn-sm#chart-dropdown {
  font-size: 1rem;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 10px;
	padding-right: 10px;
}

.scale-button-group {
	margin-left: auto;
	margin-right: 10px;
	padding-left: 65px;
	padding-top: 3px;
  padding-bottom: 3px;
}

.scale-button {
	box-shadow: none !important;
	margin: 0;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 12px;
}

.scale-button-group-mobile {
  margin-left: 10px;
	margin-right: 10px;
}

.scale-button-mobile {
	box-shadow: none !important;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 12px;
	padding-top: 6px;
}

.scale-button-img {
	height: 20px;
	width: 20px;
}

.scale-button-img-mobile {
	height: 20px;
	width: 20px;
}

.currency-button-group {
	margin-left: 0px;
	margin-right: 10px;
	padding-top: 3px;
  padding-bottom: 3px;
}

.currency-button-group-mobile {
	margin-right: auto;
}

.currency-button {
	box-shadow: none !important;
}

.currency-button-bitcoin {
	font-size: 1.5rem;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 2px; 
	padding-bottom: 2px;
}

.currency-button-mobile {
	box-shadow: none !important;
	font-size: 1rem;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: 5px;
}

.currency-button-bitcoin-mobile {
	font-size: 1.3rem;
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 2px; 
	padding-bottom: 2px;
}

.graph-tooltip {
	width: 200px;
	margin: 0;
	background-color: #FFFFFF;
	padding: 5px;
	padding-bottom: 2px;
}

.tooltip-date-price-container {
	display: flex;
	margin-bottom: 1px;
}

.tooltip-date {
	line-height: 1rem;
	margin-top: 5px;
	margin-left: auto;
	margin-right: 10px;
	font-size: 1.1rem;
	font-weight: 600;
}

.tooltip-price {
	line-height: 1rem;
	margin-top: 5px;
	margin-right: auto;
	margin-left: 10px;
	font-size: 1.1rem;
	font-weight: 600;
}

.tooltip-name-sigil-container {
	display: flex;
	margin-bottom: 1px;
	margin-top: 1px;
	margin-left: 0px;
}

.tooltip-sigil-container {
	margin-left: auto;
	margin-right: 5px;
}

.tooltip-name {
	padding-top: 6px;
	padding-left: 2px;
	padding-right: 2px;
	font-size: 1rem;
	margin-left: 5px;
	margin-right: auto;
	text-align: left;
	color: #212529;
	background-color: transparent;
	border-color: transparent;
}

.tooltip-others-container {
	margin-top: 0px;
	margin-bottom: 0px;
}

.tooltip-others {
	margin-top: 1px;
	text-align: center;
	margin-bottom: 3px;
	line-height: 1;
}

.tooltip-tip {
	font-size: 0.6rem;
	margin-bottom: 1px;
	margin-top: 1px;
}

.spawn-tooltip {
	width: 180px;
	margin: 0;
	text-align: left;
	background-color: #FFFFFF;
	padding: 10px;
	padding-right: 5px;
}

.spawn-tooltip-date {
	line-height: 1rem;
	margin-top: 3px;
	margin-bottom: 3px;
	margin-left: 5px;
	margin-right: auto;
}

.spawn-tooltip-count {
	line-height: 1rem;
	margin-top: 3px;
	margin-bottom: 3px;
	margin-left: auto;
	margin-right: 5px;
}

.stats-snapshot-container {
	display: flex;
	color: white;
	margin-top: 2px;
	margin-bottom: 5px !important;
	line-height: 1rem;
}

.stats-snapshot-volume {
	margin-left: auto;
	margin-right: 15px;
	margin-bottom: 2px !important;
}

.stats-snapshot-count {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2px !important;
}

.stats-snapshot-average {
	margin-left: 15px;
	margin-right: auto;
	margin-bottom: 2px !important;
}

.stats-snapshot-container-mobile {
	display: contents;
	color: white;
	margin-bottom: 5px !important;
}

.stats-snapshot-volume-mobile {
	margin-left: auto;
	margin-right: 5px;
	margin-bottom: 3px;
	margin-top: 5px;
}

.stats-snapshot-count-mobile {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 3px;
	margin-top: 5px;
}

.stats-snapshot-average-mobile {
	margin-left: 5px;
	margin-right: auto;
	margin-bottom: 3px;
	margin-top: 5px;
}

.react-datepicker__triangle { 
	margin-left: 4em !important; 
}

