
.buy-star-banner {
	display: inline-block; 
	margin-left: auto; 
	margin-right: auto; 
	margin-top: 10px;
}

.buy-star-banner-mobile {
	margin-left: auto; 
	margin-right: auto; 
	margin-top: 10px;
	max-width: 350px;
}

.search-results-subtitle {
	color: white !important; 
	margin-top: 15px;
	font-size: 0.85rem;
}

.buy-planet-deck {
  display: inline-flex;
  align-items: center;
  color: white;
  margin-left: auto; 
	margin-right: auto; 
}

.buy-planet-deck-mobile {
  display: inline-flex;
  color: white;
  flex-direction: row;
  margin-left: auto; 
	margin-right: auto; 
}

.buy-planet-deck-top {
	margin-top: 20px !important;
	margin-bottom: 10px !important;
}

.buy-planet-deck-other {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.buy-planet-deck-bottom {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.buy-planet-deck-bottom-straggler {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  margin-left: 50px !important;
	margin-right: 50px !important;
}

.buy-planet-card {
	cursor: pointer !important;
	background-color: #2E2E2E;
	max-width: 180px !important;
	margin-left: 10px !important;
	margin-right: 10px !important;
	transition: 0.3s;
}

.buy-planet-card-mobile {
	cursor: pointer !important;
	background-color: #2E2E2E;
	max-width: 180px !important;
	margin-left: 6px !important;
	margin-right: 6px !important;
	margin-bottom: 0px !important;
}

.buy-planet-card-mobile-link {
	color: white !important;
	text-decoration: none !important;
}

.buy-planet-card:hover {
  transform: translate(0px, -2px);
}

.buy-planet-card-selected {
	min-width: 380px !important;
}

.buy-planet-card-content-container {
	display: flex;
	text-align: left;
}

.buy-planet-card-header {
	padding-right: 6px;
	padding-left: 6px;
	font-size: 1.1rem !important;
	color: white !important;
}

.buy-planet-card-selected-header {
	padding-right: 5px;
	padding-left: 5px;
	font-size: 1.5rem !important;
}

.buy-planet-card-sigil-container {
	margin-left: 10px;
	margin-right: 10px;
}

.buy-planet-card-sigil-container-mobile {
	margin-top: 4px;
	margin-bottom: 4px;
	margin-left: 12px;
	margin-right: 12px;
}

.buy-planet-card-info-container {
	margin: 10px;
	padding-top: 10px;
}

.buy-planet-card-info-container-mobile {
	margin: 8px;
}

.buy-planet-card-sponsor-link {
	margin-left: 10px;
	color: white !important;
	text-decoration: none !important;
}

.buy-planet-card-sponsor-link:hover {
	color: grey !important;
	text-decoration: none !important;
}

.buy-planet-card-learn-more-button {
	font-size: 1.3rem !important;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 10px;
	padding-top: 10px;
	color: white !important;
	background-color: #212121 !important;
	display: inline-block !important;
	margin-top: 5px;
	margin-right: 10px;
	border-radius: 0.3rem !important;
	text-decoration: none !important;
}

.buy-planet-card-buy-button {
	font-size: 1.3rem !important;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 8px;
	padding-top: 8px;
	color: white !important;
	background-color: #00C853 !important;
	margin-top: 3px;
	margin-bottom: 10px;	
	margin-left: 10px;
	border-color: transparent;
}

.buy-planet-card-buy-button:hover {
	border-color: #212121;
}

.buy-planet-card-connect-button {
	font-size: 1.3rem !important;
	padding-left: 12px;
	padding-right: 12px;
	padding-bottom: 8px;
	padding-top: 8px;
	color: white !important;
	margin-top: 3px;
	margin-bottom: 10px;	
	margin-left: 10px;
	border-color: transparent;
}

.x-button { 
	position: absolute;
	left: 5px;
	top: 5px;
	padding: 0;
	line-height: 1;
	color: white;
	font-size: large;
}

.x-button:hover { 
	color: grey !important;
}

.buy-planet-table-container {
	margin: 10px;
}

.buy-planet-table-header-cell {
	color: white !important;
	font-size: 1.5rem;
	font-weight: 600;
	border-top-color: transparent !important;
	padding-bottom: 5px !important;
}

.buy-planet-table-header-button {
	color: white !important;
	font-size: 1.5rem;
	font-weight: 600;
	border-top-color: transparent !important;
	padding-bottom: 5px !important;
}

.buy-planet-table-sigil-row {
	color: white !important;
	border-style: none !important;
	text-align: left !important;
	text-decoration: none !important;
	font-size: 1.3rem;
	vertical-align: middle !important;
}

.buy-planet-table-sigil-container {
	margin-left: 5px;
	margin-right: 25px;
}

.buy-planet-table-name {
	margin-top: auto;
	margin-bottom: auto;
}

.buy-planet-table-data-row {
	color: white !important;
	font-size: 1.3rem;
	border-style: none !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	text-align: center !important;
	vertical-align: middle !important;
}

.buy-planet-table-buy-button {
	font-size: 1.3rem !important;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 8px;
	padding-top: 8px;
	color: white !important;
	background-color: #00C853 !important;
	border-color: transparent !important;
	cursor: pointer;
}

.buy-planet-table-buy-button:hover {
	border-color: #212121 !important;
}

.buy-planet-table-link {
	color: white !important;
	text-decoration: none !important;
	display: inline-flex !important;
	vertical-align: middle !important;
}

.buy-planet-table-link:hover {
	color: grey !important;
}

.buy-planet-table-container-mobile {
	margin-right: auto;
	margin-left: auto;
}

.buy-planet-table-header-cell-mobile {
	color: white !important;
	font-size: 1rem;
	font-weight: 600;
	border-top-color: transparent !important;
	padding-bottom: 5px !important;
}

.buy-planet-table-header-button-mobile {
	color: white !important;
	font-size: 1rem;
	font-weight: 600;
	border-top-color: transparent !important;
	padding-bottom: 5px !important;
	width: 1%;
}

.buy-planet-table-sigil-row-mobile {
	color: white !important;
	display: inline-flex;
	border-style: none !important;
	text-decoration: none !important;
	padding-left: 4px !important;
	padding-right: 4px !important;
	font-size: 0.92rem;
	vertical-align: middle !important;
}

.buy-planet-table-sigil-container-mobile {
	font-size: 0.95rem;
	margin-left: 4px;
	margin-right: 8px;
}

.buy-planet-table-data-row-mobile {
	color: white !important;
	font-size: 0.9rem;
	border-style: none !important;
	padding-left: 4px !important;
	padding-right: 4px !important;
	text-align: center !important;
	vertical-align: middle !important;
}

.buy-planet-table-link-mobile {
	color: white !important;
	text-decoration: none !important;
	display: inline-flex !important;
}

.buy-planet-table-link-mobile:hover {
	color: grey !important;
}

.buy-planet-table-buy-button-mobile {
	font-size: 0.95rem !important;
	padding-left: 8px !important;
	padding-right: 8px !important;
	padding-bottom: 5px;
	padding-top: 5px;
	margin-right: 4px;
	background-color: #00C853 !important;
	border-color: transparent !important;
}

.buy-planet-table-buy-button-mobile:hover {
	border-color: #212121 !important;
}

.search-suggestion-button {
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 20px;
	margin-top: 20px;
}

.search-suggestion-button-mobile {
	margin-left: 8px;
	margin-right: 8px;
	margin-bottom: 10px;
	margin-top: 10px;
}
