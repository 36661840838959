.help-letter-container {
	max-width: 60%;
	margin-top: 30px; 
	margin-left: auto; 
	margin-right: auto;
	padding-top: 50px;
	padding-bottom: 15px;
}

.help-letter-container-mobile {
	margin-top: 15px; 
	margin-left: 4%;
	margin-right: 4%;
	padding-top: 40px;
	padding-bottom: 15px;
}

.help-letter { 
	padding-left: 40px;
	padding-right: 40px;
	text-align: left;
	font-size: 1.1rem;
}

.help-letter-mobile { 
	text-align: left;
	font-size: 1.1rem;
}

.checklist-container {
	text-align: center;
	margin-top: 15px;
}

.checklist-item {
	font-size: 1.2rem;
	margin-top: 5px;
}

a:hover {
	color: grey !important;
	text-decoration: none !important;
}