
.contact-footer {
	background-color: #212121 !important;
	bottom: 0px;
	margin-top: 5px;
	width: 100%;
}

.footer-container {
	padding-top: 5px;
	padding-bottom: 5px;
	display: inline-flex;
	max-width: none;
	padding-right: 0px;
	padding-left: 0px;
}

.footer-container-mobile {
	padding-right: 0px;
	padding-left: 0px;
	display: inline-flex;
	padding-top: 5px;
	padding-bottom: 5px;
}

.help-button {
	padding-top: 4px;
  padding-right: 8px;
  color: white;
	background-color: transparent;
	border-style: none;
	font-size: 1.2rem;
	margin-left: 10px;
	margin-right: 10px;
	cursor: pointer;
	outline: none !important;
	transition: 0.3s;
}

.help-button:hover {
	color: grey !important;
	text-decoration: none !important;
	transform: translate(0px, -2px);
}

.help-button-mobile {
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  color: white;
	background-color: transparent;
	border-style: none;
	font-size: 1.2rem;
	margin-left: 10px;
	margin-right: 10px;
	line-height: 1.75em;
}

.contact-button {
	color: white;
	background-color: #212121 !important;
	border-style: none;
	font-size: 1.25rem !important;
	padding-left: 5px;
	padding-right: 5px;
	margin-left: 5px;
	margin-right: 5px;
}

.contact-button-mobile {
	color: white;
	background-color: #212121 !important;
	border-style: none;
	padding-top: unset;
	line-height: 1.75em;
}

.email-group {
	display: flex;
	margin-top: 5px;
	margin-right: 8px;
}

.email-group-mobile {
	display: flex;
	margin-top: 5px;
	margin-right: 5px;
}

.email-address {
	color: white;
	font-size: 1.1rem;
	margin-top: 1px;
	margin-right: 2px;
	margin-left: 10px;
	margin-bottom: 5px;
}

.email-address-copied {
	color: #787878;
	font-size: 1.1rem;
	margin-right: 48px;
	margin-left: 48px;
	margin-bottom: 6px;
	/*padding-top: 0px;*/
	/*padding-bottom: 0px;*/
}

.copy-button {
	color: #999999;
	height: 25px;
	margin-right: 2px;
	margin-left: 0px;
	padding-top: 3px;
	background-color: transparent;
	border-style: none;
	outline: none !important;
	cursor: pointer !important;
	transition: 0.3s;
}

.copy-button:hover { 
	color: #595959;
	transform: translate(0px, -1px);
}

.icon-group {
	margin-right: 0px;
	margin-left: auto;
}

.icon-group-mobile {
	margin-right: auto;
	margin-left: auto;
}

.media-icon {
	margin-left: 15px;
	margin-right: 15px;
  color: white;
  font-size: 1.5rem;
  transition: 0.3s;
}

.media-icon:hover { 
	color: grey;
	transform: translate(0px, -2px);
}

.media-icon-mobile {
	margin-left: 12px;
	margin-right: 12px;
  color: white;
  font-size: 1.5rem;
  transition: 0.3s;
}
