
.title-container {
	color: white;
	font-size: 2.5rem;
	margin-bottom: 35px;
	padding-top: 10px;
}

.title-container-mobile {
	color: white;
	font-size: 2rem;
	margin-bottom: 30px;
	padding-top: 10px;
}

.shop-button-container {
	max-width: 625px;
	margin-top: 20px;
	margin-bottom: 70px;
	margin-left: auto;
	margin-right: auto;
}

.shop-button-container-mobile-portrait {
	margin-top: 20px;
	margin-bottom: 50px;
	margin-left: 40px;
	margin-right: 40px;
}

.shop-button-container-mobile-landscape {
	max-width: 425px;
	margin-top: 20px;
	margin-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
}

.shop-button {
	color: #2E2E2E;
	padding-top: 30px;
	padding-bottom: 20px;
	transition: 0.3s;
}

.shop-button:hover {
	text-decoration: none;
	color: #212121;
  transform: translate(0px, -3px);
}

.shop-button-mobile {
	color: #2E2E2E;
	padding-top: 30px;
	padding-bottom: 20px;
	transition: 0.3s;
}

.shop-button-mobile:hover {
	text-decoration: none;
	color: #212121;
}

.shop-button-icon-container {
	margin-left: 5px;
	margin-right: auto;
	font-size: 3.5rem;
	display: flex;
}

.shop-button-icon {
	margin-left: -6px !important;
	margin-right: -6px !important;
	padding-top: 10px !important;
}

.shop-button-icon-middle {
	margin-left: -4px !important;
	margin-right: -4px !important;
	padding-top: 14px !important;
}

.shop-button-text-container {
	margin-left: auto;
	margin-right: auto;
}

.shop-button-text-container-mobile {
	margin-left: auto;
	margin-right: auto;
}

.shop-button-text-title {
	font-size: 2.5rem;
	font-weight: 550;
}

.shop-button-text-title-mobile {
	font-size: 1.8rem;
	font-weight: 550;
}

.carat-container {
	margin-left: auto;
	font-size: 3.5rem;
}

.carat-container-mobile {
	margin-left: auto;
	font-size: 2.6rem;
}

.search-field-container {
	margin-top: 60px;
	margin-bottom: 55px;
	width: 500px;
	display: inline-block;
}

.search-field-container-mobile {
	margin-top: 20px;
	margin-bottom: 30px;
	display: inline-block;
	max-width: 85vw;
}

.search-field-input-group {
	margin-left: auto;
	margin-right: auto;
	transition: 0.3s;
}

.search-field-input-group-mobile {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 45px;
	transition: 0.3s;
}

.search-field-text-title-mobile {
	color: white;
	font-size: 1.7rem;
	margin-bottom: 10px;
}

.search-field-input-group:hover {
  transform: translate(0px, -2px);
}

.search-field-form-control {
	width: 250px;
}

.search-field-length-feedback {
	height: 9px !important;
  text-align: right !important;
  margin-top: 1px !important;
  line-height: 1rem !important;
  color: #FFFFFF !important;
}

.search-field-length-feedback-mobile {
	height: 9px !important;
  text-align: left !important;
  margin-top: 1px !important;
  line-height: 1rem !important;
  color: #FFFFFF !important;
  padding-left: 60px !important;
}

.search-field-no-match-feedback {
	height: 9px !important;
  text-align: right !important;
  margin-top: 1px !important;
  line-height: 1rem !important;
  color: #ff4444 !important;
}

.search-field-no-match-feedback-mobile {
	height: 9px !important;
  text-align: left !important;
  margin-top: 1px !important;
  line-height: 1rem !important;
  color: #ff4444 !important;
  padding-left: 60px !important;
}
