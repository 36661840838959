
.navbar-desktop {
	background-color: #212121;
	padding-right: 2px;
	padding-top: 2px;
	padding-left: 10px;
}

.navbar-mobile {
	background-color: #212121;
	padding-right: 2px;
	padding-left: 2px;
	padding-top: 4px;
	padding-bottom: 2px;
}

.navbar-brand-img {
	transition: 0.3s;
}

.navbar-brand-img:hover {
	transform: translate(0px, -2px);
}

.header-nav-link-selected { 
	color: white !important;
	font-size: 1.4rem !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
	padding-top: 20px !important;
	margin-left: 8px !important;
	margin-right: 8px !important;
	border-bottom-style: solid !important;
  border-bottom-width: medium !important;
  transition: 0.3s;
}

.header-nav-link-selected:hover {
	color: grey !important;
	border-bottom-style: solid !important;
  border-bottom-width: medium !important;
  transform: translate(0px, -2px);
}

.header-nav-link-hover { 
	color: white !important;
	font-size: 1.4rem !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
	padding-top: 20px !important;
	margin-left: 5px !important;
	margin-right: 5px !important;
	transition: 0.3s;
}

.header-nav-link-hover:hover {
	color: grey !important;
	transform: translate(0px, -2px);
}

.input-group-prepend > .btn {
	font-size: 1.15rem !important;
	padding-left: 8px !important;
	padding-right: 8px !important;
}

.popover-header {
	background-color: #212121 !important;
	color: white;
}

.popover-body {
	padding: 0 !important;
}

.input-group > .input-group-append:not(:last-child) > .btn {
	border-color: white;
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.input-group > .form-control,
.input-group > .form-control:focus {
  background-color: transparent;
  color: white;
  box-shadow: none;
  border-color: white;
}

.form-control.is-valid,
.form-control.is-invalid,
.form-control.is-valid:focus,
.form-control.is-invalid:focus {
  border-color: white;
  background-image: none;
}

.form-control ~ .valid-feedback,
.form-control:focus ~ .valid-feedback,
.form-control ~ .invalid-feedback,
.form-control:focus ~ .invalid-feedback {
  height: 9px;
  text-align: left;
  margin-top: 1px;
  line-height: 1rem;
  padding-left: 100px;
  color: #ff4444;
}

.galaxy-item { 
	font-size: 1rem; 
	display: flex;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	align-items: center;
	background-color: white;
}

.selected-galaxy-item { 
	font-size: 1rem; 
	display: flex;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	align-items: center;
	background-color: #343a40 !important;
	color: white !important;
}

.galaxy-popover { 
	max-height: 50vh;
	overflow-y: scroll;
	-webkit-overflow-scrolling: auto;
	overflow-scrolling: auto;
}

.galaxy-popover-mobile { 
	max-height: 50vh;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
}

.stats-summary-spinner {
	padding-right: 30px;
	padding-top: 5px;
}

.stats-summary-table-container {
	margin-top: 2px;
	text-align: right;
}

.stats-summary-table-header-row {
	text-align: center;
	color: grey !important;
	border-style: none !important;
	padding-top: 4px !important;
	padding-bottom: 2px !important;
	padding-left: 5px !important;
	padding-right: 8px !important;
	font-size: 0.7rem;
}

.stats-summary-table-data-row {
	color: white;
	font-size: 0.95rem;
	padding-left: 4px !important;
	padding-right: 4px !important;
	padding-top: 4px !important;
	padding-bottom: 4px !important;
	line-height: 0.8rem;
	text-align: center;
	vertical-align: middle;
	border-style: none !important;
}

.stats-summary-table-data-infinity {
	color: #00C853;
	font-size: 1.4rem; 
	vertical-align: middle;
}

.stats-summary-currency-button-group {
	margin-top: 4px;
	padding-top: 3px;
  padding-bottom: 3px;
  margin-right: 10px;
}

.stats-summary-currency-button {
	box-shadow: none !important;
	font-size: 1rem;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 1px; 
	padding-bottom: 1px;
}

.stats-summary-currency-button-bitcoin {
	font-size: 1.1rem;
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 1px; 
	padding-bottom: 1px;
}

.dropdown-menu .sub-menu {
  left: 100%;
  position: absolute;
  top: 0px;
  visibility: hidden;
  margin-top: 0px;
}

.dropdown-menu li:hover .sub-menu {
  visibility: visible;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.nav-tabs .dropdown-menu,
.nav-pills .dropdown-menu,
.navbar .dropdown-menu {
  margin-top: 0;
}

.header-nav-dropdown-item {
  font-size: 1.3rem;
}
