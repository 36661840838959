
.star-banner {
	display: inline-block; 
	margin-left: auto; 
	margin-right: auto; 
	margin-top: 10px;
	margin-bottom: 10px;
}

.star-banner-mobile {
	margin-left: auto; 
	margin-right: auto; 
	margin-top: 10px;
	max-width: 350px;
}

.planet-deck {
  display: inline-flex;
  align-items: center;
  color: white;
}

.planet-deck-mobile {
  display: inline-flex;
  color: white;
  flex-direction: row;
}

.planet-deck-top {
	margin-top: 20px !important;
	margin-bottom: 10px !important;
}

.planet-deck-other {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.planet-deck-bottom {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.planet-card {
	max-width: 180px !important;
	cursor: pointer;
	margin-left: 10px !important;
	margin-right: 10px !important;
	transition: 0.3s;
	background-color: #2E2E2E;
}

.planet-card:hover {
  transform: translate(0px, -2px);
}

.planet-card-mobile {
	max-width: 180px !important;
	cursor: pointer;
	margin-left: 6px !important;
	margin-right: 6px !important;
	background-color: #2E2E2E;
	margin-bottom: 0px !important;
}

.planet-card-sigil-container {
	margin-left: 10px;
	margin-right: 10px;
}

.planet-card-single {
	margin-left: auto !important;
	margin-right: auto !important;
}

.planet-card-selected {
	width: 15vw !important;
	margin: 1vmin !important;
	min-width: 260px !important;
	max-width: 275px !important;
}

.planet-card-header {
	padding-right: 6px;
	padding-left: 6px;
	font-size: 1.1rem !important;
}

.planet-card-footer {	
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.planet-card-footer-info {
	text-align: left;
	margin-left: 10px !important;
	padding-left: 0px !important;
	padding-top: 2px !important;
	padding-bottom: 2px !important;
}

.planet-card-link {
	color: white !important;
	text-decoration: none !important;
}

.planet-purchase-button {
	font-size: 1.2rem !important;
	font-weight: bold !important;
	padding-left: 4px !important;
	padding-right: 4px !important;
	color: white !important;
	background-color: #28A745 !important;
	width: 235px !important;
}

.planet-info-btn {
	font-size: 1.2rem !important;
	line-height: 1.5 !important;
	padding-left: 0.5rem !important;
	padding-right: 0.5rem !important;
	padding-bottom: 0.5rem !important;
	padding-top: 0.5rem !important;
	color: white !important;
	background-color: #212121 !important;
	width: 170px !important;
	display: inline-block !important;
	margin-top: 15px !important;
	margin-bottom: 10px !important;	
	border-radius: 0.3rem !important;
	text-decoration: none !important;
}

.x-button { 
	position: absolute;
	left: 1vmin;
	top: 1vmin;
	padding: 0;
	line-height: 1;
	color: white;
	font-size: large;
}

.x-button:hover { 
	color: grey !important;
}

.loading-bar {
	margin: 25px;
	min-width: 100px;
}

.galaxy-list-item-container {
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 20vw;
	margin-left: 20vw;
}

.galaxy-list-item {
	transition: 0.3s;
}

.galaxy-list-item:hover {
	transform: translate(0px, -3px);
}

.galaxy-list-item-column {
	margin-left: 5px;
	margin-right: 5px;
	flex-grow: 0;
}

.search-suggestion-button {
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 20px;
	margin-top: 20px;
	background-color: #2E2E2E !important;
}

.search-suggestion-button-mobile {
	margin-left: 8px;
	margin-right: 8px;
	margin-bottom: 10px;
	margin-top: 10px;
}
